.buttonRow {
    display: flex;
    flex-direction: row;
    margin: 6px -6px !important;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.button {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    height: 40px;
    border-radius: var(--border-radius);
    color: #DBDBDB;
    font-size: 13px;
    font-weight: 600;
    background: var(--color-container);
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    justify-content: center;
    margin: 6px;
    text-decoration: none;
    outline: none;
    font-family: "Montserrat";
    max-width: 400px;
    justify-content: space-between;
    text-decoration: none;
    text-decoration-color: transparent;
    box-sizing: border-box;
    border: none;
}


.dropdownButton {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-second);
    padding: 10px 20px;
    height: 40px;
    border-radius: 8px;
    color: #DBDBDB;
    font-size: 13px;
    font-weight: 500;
    background: var(--color-container-v2);
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    justify-content: center;
    margin: 6px;
    text-decoration: none;
    outline: none;
    font-family: "Montserrat";
    max-width: 400px;
    justify-content: space-between;
    text-decoration: none;
    text-decoration-color: transparent;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 7px;
}


.PlayerButton {
    color: var(--color-text);
    cursor: pointer;
    margin-right: 15px;
    padding: 5px 10px;
    border: 1px solid var(--color-second-v2);
    border-radius: 5px;
    background: var(--color-container-v2);
}


.container {
    position: relative;
    display: inline-block;
}

.dropdown {
    position: absolute;
    top: 23px;
    right: -50px;
    width: 200px;
    margin: 6px 2px;
    border-radius: 8px;
    z-index: 2000;
    border: none;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.38);
    background: var(--color-container-v2);
    overflow: hidden;
}

.buttonDropdown {
    position: absolute;
    top: 41px;
    right: -55px;
    width: 200px;
    margin: 6px 2px;
    border-radius: 8px;
    z-index: 2000;
    border: none;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.38);
    background: var(--color-container-v2);
    overflow: hidden;
}

.ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.li {
    padding: 8px 12px;

}

.li:hover {
    background-color: rgba(0, 0, 0, 0.14);
    cursor: pointer;
}

.button:hover {
    background: var(--color-container-v2);
    cursor: pointer;
}

.button:disabled, a:disabled {
    background: var(--color-container);
    color: var(--color-text-low);
    cursor: not-allowed;
}

.TextInput {
    border: none;
    outline: none;
    border-radius: var(--border-radius);
    padding: 10px 5px 10px 20px;
    height: 40px;
    background: var(--color-container);

    font-weight: 500;
    font-size: 13px;
    color: var(--color-text);
    font-family: "Montserrat";
    width: 100%;
}

.TextInputWrapper {
    display: flex;
    max-width: 400px;
    margin: 6px 0;
    flex-grow: 2;
}

.SmallButton {
    display: flex;
    color: var(--color-text);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    justify-content: center;
}

.TextInput:disabled {
    background: var(--color-second-v1);
    color: var(--color-text-low);
    cursor: not-allowed;
}

.TextInput:focus {
    outline: 2px solid var(--color-purple);
    background: var(--color-container-v2);
    color: var(--color-text-high);
}

.Switch, .SwitchActive {
    position: relative;
    height: 16px;
    width: 36px;
    border-radius: 8px;
    overflow: hidden;
}

.SwitchInner {
    position: relative;
    display: block;
    width: 36px;
    height: 16px;
    margin-top: 0px;
    z-index: 0;
    overflow: hidden;
    border-radius: 8px;
}

.SwitchHandle {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: var(--color-text-low);
    display: block;
    margin: -12px 0 0 0;
    left: 4px;
    z-index: 2;
    transition: all 0.1s ease-out;
}

.SwitchOff, .SwitchOn {
    display: inline-block;
    background: var(--color-second);
    width: 36px;
    height: 16px;
    position: absolute;
    transition: all 0.1s ease-out;
    z-index: 0;
    border-radius: 8px;
}

.SwitchOn {
    background: var(--color-purple);
}

.Switch .SwitchOff {
    transform: translateX(0%);
}

.Switch .SwitchOn {
    transform: translateX(-100%);
}

.SwitchActive .SwitchOff {
    transform: translateX(100%);
}

.SwitchActive .SwitchOn {
    transform: translateX(0%);
}

.SwitchActive .SwitchHandle {
    left: 24px;
    background-color: white;
}

.SwitchRow {
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
}

.SwitchRow>span {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
    color: var(--color-text);
}

.ChoosePageButton, .ChoosePageButtonActive {
    padding: 0 20px;
    height: 35px;
    border-radius: var(--border-radius);
    align-items: center;
    color: var(--color-text);
    font-size: 13px;
    font-weight: 600;
    display: flex;
    margin: 0 6px;
    flex-direction: row;
    justify-content: center;
    font-family: "Montserrat";
    border-bottom-color: transparent;
    position: relative;
    white-space: nowrap;
}

.ChoosePageButton:hover {
    color: var(--color-text);
    cursor: pointer;
}

.ChoosePageButtonActive {
    background: var(--color-container);
    color: var(--color-text);
}

.ChoosePageButtonHolder {
    display: flex;
    flex-direction: row;
    margin: 18px -6px !important;
    align-items: center;
    z-index: 2;
    position: relative;
}

.selectableRow, .selectableRowSelected {
    height: 40px;
    display: flex;
    flex-direction: row;
    padding: 12px 20px;
    border-radius: 10px;
    margin: 4px 0;
    align-items: center;
    cursor: pointer;
}

.selectableRow:hover {
    background: var(--color-container);
}

.selectableRowSelected {
    background: var(--color-container-v2);
}

.iconSelect {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    color: var(--color-text);
    flex-shrink: 0;
}

.selectableRow:hover>.iconSelect, .selectableRowSelected>.iconSelect {
    color: var(--color-text-high);
}