@import "assets/fonts/monsterrat/style.css";

:root {
    --color-base: #181a20;
    --color-container: #1f222a;
    --color-container-v2: #262a34;
    --color-container-v3: #4f5360;
    --color-container-v4: #1c1e25;

    --color-second: var(--color-container-v3);
    --color-second-v1: #1F1F28;
    --color-second-v2: #262631;

    --color-text: #d0d0d0;
    --color-text-low: #656a7b;
    --color-text-high: #ffffff;

    --color-purple: #246bfe;
    --color-purple-low: #3878FE;

    --border-radius: 12px;
}

body {
    margin: 0;
    background: var(--color-base);
    color: var(--color-text-high);

    /* Fonts */
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;
}


* {
    box-sizing: border-box;
}

a {
    font-weight: 600;
    font-size: 14px;
    color: var(--text-color);
}

h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 8px 24px 12px 18px;
}

h5 {
    font-size: 13px;
    font-weight: 600;
    margin: 0 24px 8px 18px;
    color: var(--color-text);
    line-height: 1.5em;
}

p {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: var(--color-text);
    margin: 0 24px 8px 18px;
}

table {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--color-text);
}

b {
    font-weight: 600;
    color: var(--color-text-high);
}