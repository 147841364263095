.card {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding: 12px 18px;
    min-width: 300px;
    margin: 12px 0 24px 0;
    background: var(--color-container-v4);
    border-radius: var(--border-radius);
}

.CardRow {
    color: var(--color-text);
    padding: 6px 0;
}

.PageCard {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    padding: 12px 18px;
    min-width: 300px;
    margin: 0 0 24px 0;
    background: var(--color-container-v4);
    border-radius: var(--border-radius);
}

.Tag {
    background-color: var(--color-second-v2);
    padding: 4px 10px;
    border-radius: 5px;
    color: var(--color-text);
    margin-left: 10px;
}

.modalDisabled {
    display: none;
}

.modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    z-index: 90000;
    height: 0;
    width: 0;
}

.modalContent {
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    max-width: 450px;
   
    padding: 24px;
    border-radius: var(--border-radius);
    background-color: var(--color-container-v4);
    z-index: 5;
}

.modalContent > h2 {
    margin: 0 0 8px 0;
}

.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
}